import {ExperimentNames, getEventId, isDraft, isTicketed} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {updateDates} from '../../../../commons/actions/dates'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {isSchedulePageInstalled} from '../../../../commons/utils/wix-code-api'
import {getEvent as getEventSelector, isDemoEvent} from '../selectors/event'
import {isGroupsInstalled} from '../selectors/groups'
import {GetState, StoreExtraArgs} from '../types'
import {getDraftToken} from '../utils/query'
import {getCheckoutOptions} from './checkout-options'
import {getConnectedGroup} from './groups'
import {navigate} from './navigation'
import {getPlanList} from './paid-plans'
import {getMemberRsvp} from './rsvp'

export const SHARE_EVENT = 'SHARE_EVENT'
export const CHANGE_EVENT = 'CHANGE_EVENT'
export const GET_EVENT = createActions('GET_EVENT')
export const GET_MEMBERS = createActions('GET_MEMBERS')

export const changeEvent =
  (slug: string) =>
  async (dispatch: Function, getState: GetState, {wixCodeApi, serverApi, flowAPI}: StoreExtraArgs) => {
    const state = getState()
    const responsive = !!state.component.settings?.responsive
    const draftPreviewToken = getDraftToken(wixCodeApi)
    const schedulePageInstalled = await isSchedulePageInstalled(wixCodeApi)
    const newClassicDemoEvents = flowAPI.experiments.enabled(ExperimentNames.UpdatedClassicAddPanel)

    const {event, richContent, dates, tickets, schedule} = await serverApi.getData({
      slug,
      responsive,
      schedulePageInstalled,
      draftPreviewToken,
      flowAPI,
      newClassicDemoEvents: newClassicDemoEvents ? true : undefined,
    })
    await dispatch(updateDates(dates))
    await dispatch({type: CHANGE_EVENT, payload: {event, richContent, tickets, schedule}})

    dispatch(getMemberRsvp())
    dispatch(getExtraEventData())

    dispatch(navigate(DETAILS_ROUTE.DETAILS))
  }

export const getExtraEventData =
  () =>
  (dispatch: Function, getState: GetState): Promise<any>[] => {
    const state = getState()
    const event = getEventSelector(state)
    const promises = []

    if (event && !isDraft(event)) {
      promises.push(dispatch(getMembers()))

      if (state.paidPlansEnabled) {
        promises.push(dispatch(getPlanList()))
      }

      if (isTicketed(event)) {
        promises.push(dispatch(getCheckoutOptions()))
      }

      if (isGroupsInstalled(state)) {
        promises.push(dispatch(getConnectedGroup()))
      }
    }

    return promises
  }

export const getEvent = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const event = getEventSelector(state)

  if (!isDemoEvent(state)) {
    return dispatch(callAPI(GET_EVENT, getEventId(event)))
  }
}

export const getMembers = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const eventId = getEventSelector(state).id

  if (state.membersAreaEnabled && eventId && !isDemoEvent(state)) {
    return dispatch(callAPI(GET_MEMBERS, eventId))
  }
}

export const shareEvent = (buttonName: string, origin: string) => ({type: SHARE_EVENT, payload: {buttonName, origin}})
